<template>
  <div element-loading-text=""
       element-loading-background="rgba(0, 0, 0, 0.3)"
       class="orderPage"
       v-loading.fullscreen.lock="fullscreenLoading">
    <div class="main zhu base-w">
      <!-- 头部 -->
      <div class="head" style="border-bottom: none">
        <span class="flexAndCenter"><font class="headT1">{{ $fanyi("注文NO") }}</font><span
            class="headT1">:{{ datas.order_sn }}</span></span>
        <div class="flexAndCenter">
          <div class="headerSearchContainer flexAndCenter">
            <div class="inputContainer flexAndCenter">
              <el-input v-model="input" :placeholder="$fanyi('请输入想搜索的URL')" style="width:340px"/>
            </div>
            <!--            <div class="searchBtn" @click="linkSearch">{{ $fanyi('搜索') }}</div>-->
            <i class="el-icon-search" @click="linkSearch"></i>
          </div>
        </div>
      </div>
      <div class="tabsContainer">
        <el-button type="primary" @click="issueOrderDetailPageJump">
          {{ $fanyi('订单问题产品') }}
        </el-button>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane :label="$fanyi('当前订单状态')" name="first"></el-tab-pane>
          <el-tab-pane :label="$fanyi('金额变更明细')" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 订单列表 -->
      <div class="obligationOrder" v-show="activeName==='first'">
        <div>
          <ul class="flexAndCenter obligationOrderHeader">
            <li class="inputBox">{{ $fanyi("选择") }}</li>
            <li class="mirror">No</li>
            <li class="mirror">{{ $fanyi("写真") }}</li>
            <li class="goods">{{ $fanyi("商品规格") }}</li>
            <li class="dataAtTheTimeOfEntry">{{ $fanyi("入金时数据") }}</li>
            <li class="dataAtTheTimeOfEntry"><span>{{ $fanyi("最新数值") }}</span></li>
            <li class="dataAtTheTimeOfEntry" style="width:100px"><span>{{ $fanyi("出荷数") }}</span></li>
            <li class="optionSelect">
              <div class="flexAndCenter">
                <div>{{ $fanyi("オプション") }}</div>
                <el-popover trigger="hover" placement="top">
                  <div style="width: 320px">
                    <div style="margin-bottom: 20px">
                      下記記載のサービスはあくまで基本的なもので、
                    </div>
                    <div style="margin-bottom: 20px">
                      記載されていないサービスなど、新規需要などあれば担当者までご相談していただき、
                    </div>
                    <div>対応可能か個別で確認返答させていただきます。</div>
                  </div>
                  <img src="../../../../../../assets/4.png" style="margin:6px 0 0 6px" slot="reference">
                </el-popover>
              </div>
            </li>
            <li class="tagName">{{ $fanyi("标签编号") }}</li>
            <li class="theDomesticFeed">{{ $fanyi("備考欄") }}</li>
            <li class="salesmanRemark">{{ $fanyi("スタッフ") }}</li>
            <li class="status">{{ $fanyi("状态") }}</li>
            <li class="distributionInformation">{{ $fanyi("配送信息") }}</li>
          </ul>
          <div class="goodsBox">
            <div v-for="(item,index) in newList" :key="item.id">
              <ul v-if="item.arr==undefined" class="goodsConUl">
                <!-- 全选 -->
                <li class="inputBox" style="padding-top: 0">
                  <div class="flexAndCenterAndCenter" style="flex-direction: column">
                    <el-popover placement="right" title="合计数量" trigger="focus" width="200">
                      <statistics :checkedList="checkedList" :edit="updateOrderStatus"/>
                      <el-checkbox slot="reference"
                                   v-model="item.checked"
                                   style="margin:0"
                                   type="checkbox"
                                   @change="radio(index)"
                      />
                    </el-popover>
                  </div>
                </li>
                <li class="mirror" style="padding-top: 0">
                  <div class="cursorPointer" style="display: flex;flex-direction: column;align-items: center"
                       @click="getEditSnInfo(item)">
                    <div>{{ item.sorting }}</div>
                    <div v-if="item.id!=snInfo.id" :title="item.for_sn"
                         class="u-line"
                         style="line-height: normal;cursor: pointer;width: 100px;text-align: center">{{ item.for_sn }}
                    </div>
                    <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                              style="margin-top:-30px"
                              @blur="updateForSn(item)" @input="$forceUpdate()"/>
                  </div>
                </li>
                <!--            写真-->
                <li class="mirror" style="flex-direction: column">
                  <el-popover placement="right" trigger="hover">
                    <el-image :src="item.pic"
                              style="width: 300px; height: 300px"
                    />
                    <el-image
                        slot="reference"
                        :src="item.pic" style="width: 60px;height: 60px"
                        @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                    />
                  </el-popover>
                  <el-popover placement="bottom" trigger="hover">
                    <div>{{ $fanyi("手续费") }}：{{
                        $fun.returnServiceRate(item.service_rate)
                      }}%
                    </div>
                    <div slot="reference" style="line-height: 20px;margin-top: -30px">{{ item.from_platform }}</div>
                  </el-popover>
                </li>
                <!-- 商品详细 -->
                <li class="goods">
                  <div class="showDetail">
                    <el-popover placement="bottom" trigger="hover">
                      <div class="showDetailCon" style="line-height: normal">
                        <p v-for="(bitem, bindex) in item.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value"
                           class="ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                      <div slot="reference" class="showDetailCon" style="line-height: normal">
                        <p v-for="(bitem, bindex) in item.detail"
                           :key="bitem.key + bindex"
                           :title="bitem.key + ':' + bitem.value"
                           class="ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>
                    </el-popover>
                  </div>
                </li>
                <!-- 入金时数据 -->
                <li class="dataAtTheTimeOfEntry">
                  <div>
                    <div>{{ $fanyi("数量") }}：{{ item.is_pay_data.num }}</div>
                    <div>{{ $fanyi("单价") }}：{{ item.is_pay_data.price }}</div>
                    <div>{{ $fanyi("运费") }}{{ item.is_pay_data.freight }}</div>
                  </div>
                </li>
                <!-- 最新数值 -->
                <li class="dataAtTheTimeOfEntry">
                  <div>
                    <div>{{ $fanyi("数量") }}：{{ item.is_now_data.num }}</div>
                    <div>{{ $fanyi("单价") }}：{{ item.is_now_data.price }}</div>
                    <div>{{ $fanyi("运费") }}{{ item.is_now_data.freight }}</div>
                  </div>
                </li>
                <!-- 出荷数 -->
                <li class="dataAtTheTimeOfEntry" style="width:100px">
                  <div>
                    <div>{{ $fanyi("到着") }}：{{ item.in_out_data.in_num }}</div>
                    <div>{{ $fanyi("出荷") }}：{{ item.in_out_data.out_num }}</div>
                  </div>
                </li>
                <!-- オプション -->
                <li class="optionSelect" style="margin-top:-10px">
                  <el-popover trigger="hover">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex" style="text-align: left;">
                      <div v-if="optionItem.num>0&&optionItem.checked===true">{{ optionIndex + 1 }}.{{
                          optionItem.name_translate
                        }}
                        *{{ optionItem.num }}({{
                          optionItem.price
                        }}{{ optionItem.unit }}/点)
                      </div>
                    </div>
                    <div slot="reference">
                      <div class="optionHiddenContainer">
                        <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                             style="text-align: left;width:149px">
                          <div v-if="optionItem.num>0&&optionItem.checked===true" class="u-line">
                            {{ optionIndex + 1 }}.{{
                              optionItem.name_translate
                            }} *{{ optionItem.num }}({{
                              optionItem.price
                            }}{{ optionItem.unit }}/点)
                          </div>
                        </div>
                      </div>
                      <div v-show="item.optionCheckedNum>3">........</div>
                    </div>
                  </el-popover>
                </li>
                <!-- 标签编号 -->
                <li class="tagName" style="flex-direction: column">
                  <el-popover trigger="hover">
                    <div v-for="(optionItem,optionIndex) in item.order_detail_tag" :key="optionIndex">
                      <div class="color333 fontSize14 lineHeight20 ">{{ optionItem.type_translate }}</div>
                      <div class="color333 fontSize14 lineHeight20 ">{{ optionItem.no }}</div>
                      <div class="color333 fontSize14 lineHeight20">{{ optionItem.goods_no }}</div>
                    </div>
                    <div v-for="(optionItem,optionIndex) in item.order_detail_tag" :key="optionIndex"
                         slot="reference"
                         class="tagContainer">
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.type_translate }}</div>
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.no }}</div>
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.goods_no }}</div>
                    </div>
                  </el-popover>
                </li>
                <!-- 備考欄 -->
                <li class="theDomesticFeed">
                  <el-popover trigger="hover">
                    <div>{{ item.client_remark }}</div>
                    <div slot="reference" class="clientRemark">{{ item.client_remark }}</div>
                  </el-popover>
                </li>
                <!-- スタッフ -->
                <li class="salesmanRemark">
                  <div :title="item.y_reply" class="u-line-3" style="line-height: 20px">
                    {{ item.y_reply }}
                  </div>
                </li>
                <!--状态 -->
                <li class="status">
                  <div style="line-height: 15px">
                    {{
                      item.problem_goods_status != null ? $fanyi(item.problem_goods_status) : $fanyi(item.status_name)
                    }}
                  </div>
                </li>
                <!--配送信息 -->
                <li class="distributionInformation">
                  <div class="fontSize14" style="text-decoration: underline;color: #B4272B;cursor: pointer"
                       @click="getPorderMessage(item)">{{ $fanyi('查看') }}
                  </div>
                </li>
              </ul>
              <div v-else style="border-radius: 10px;border:1px solid #FFCF96;overflow: hidden;position: relative">
                <!-- 标签编辑 -->
                <div class="tagListContainer">
                  <el-popover trigger="hover">
                    <div v-for="(optionItem,optionIndex) in item.order_detail_tag" :key="optionIndex">
                      <div class="color333 fontSize14 lineHeight20 ">{{ optionItem.type_translate }}</div>
                      <div class="color333 fontSize14 lineHeight20 ">{{ optionItem.no }}</div>
                      <div class="color333 fontSize14 lineHeight20">{{ optionItem.goods_no }}</div>
                    </div>
                    <div v-for="(optionItem,optionIndex) in item.order_detail_tag" :key="optionIndex"
                         slot="reference"
                         class="tagContainer">
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.type_translate }}</div>
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.no }}</div>
                      <div class="color333 fontSize14 lineHeight20 u-line">{{ optionItem.goods_no }}</div>
                    </div>
                  </el-popover>
                </div>
                <ul v-for="(goodsItem,goodsIndex) in item.arr" :key="goodsIndex"
                    :style="goodsItem.set_detail_id<0?'background:#FFF0DE;':'background:#FFF8ED;'"
                    class="goodsConUl">
                  <!-- 全选 -->
                  <li class="inputBox" style="padding-top: 0">
                    <div class="flexAndCenterAndCenter" style="flex-direction: column">
                      <div v-if="goodsItem.set_detail_id<0" class="setGoodsContainer">组套商品</div>
                      <div v-else class="inBundleGoods" style="line-height: normal">
                        <img alt="" src="../../../../../../assets/link.svg">同捆物{{ goodsIndex }}
                      </div>
                      <el-popover placement="right" title="合计数量" trigger="focus" width="200">
                        <statistics :checkedList="checkedList" :edit="updateOrderStatus"/>
                        <el-checkbox slot="reference"
                                     v-model="goodsItem.checked"
                                     style="margin:0"
                                     type="checkbox"
                                     @change="radio(goodsIndex)"
                        />
                      </el-popover>
                    </div>
                  </li>
                  <li class="mirror" style="padding-top: 0">
                    <div class="cursorPointer" style="display: flex;flex-direction: column;align-items: center"
                         @click="getEditSnInfo(goodsItem)">
                      <div>{{ goodsItem.sorting }}</div>
                      <div v-if="item.id!=snInfo.id" :title="goodsItem.for_sn"
                           class="u-line"
                           style="line-height: normal;cursor: pointer;width: 100px;text-align: center">
                        {{ goodsItem.for_sn }}
                      </div>
                      <el-input v-else v-model="goodsItem.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                                style="margin-top:-30px"
                                @blur="updateForSn(goodsItem)" @input="$forceUpdate()"/>
                    </div>
                  </li>
                  <!--            写真-->
                  <li class="mirror" style="flex-direction: column">
                    <el-popover placement="right" trigger="hover">
                      <el-image :src="goodsItem.pic"
                                style="width: 300px; height: 300px"
                      />
                      <el-image
                          slot="reference"
                          :src="goodsItem.pic" style="width: 60px;height: 60px"
                          @click="$fun.toCommodityDetails(goodsItem.goods_id,goodsItem.from_platform)"
                      />
                    </el-popover>
                    <el-popover placement="bottom" trigger="hover">
                      <div>{{ $fanyi("手续费") }}：{{
                          $fun.returnServiceRate(goodsItem.service_rate)
                        }}%
                      </div>
                      <div slot="reference" style="line-height: 20px;margin-top: -30px">{{
                          goodsItem.from_platform
                        }}
                      </div>
                    </el-popover>
                  </li>
                  <!-- 商品详细 -->
                  <li class="goods">
                    <div class="showDetail">
                      <el-popover placement="bottom" trigger="hover">
                        <div class="showDetailCon" style="line-height: normal">
                          <p v-for="(bitem, bindex) in goodsItem.detail"
                             :key="bitem.key + bindex"
                             :title="bitem.key + ':' + bitem.value"
                             class="ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                        <div slot="reference" class="showDetailCon" style="line-height: normal">
                          <p v-for="(bitem, bindex) in goodsItem.detail"
                             :key="bitem.key + bindex"
                             :title="bitem.key + ':' + bitem.value"
                             class="ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                      </el-popover>
                    </div>
                  </li>
                  <!-- 入金时数据 -->
                  <li class="dataAtTheTimeOfEntry">
                    <div>
                      <div>{{ $fanyi("数量") }}：{{ goodsItem.is_pay_data.num }}</div>
                      <div>{{ $fanyi("单价") }}：{{ goodsItem.is_pay_data.price }}</div>
                      <div>{{ $fanyi("运费") }}{{ goodsItem.is_pay_data.freight }}</div>
                    </div>
                  </li>
                  <!-- 最新数值 -->
                  <li class="dataAtTheTimeOfEntry">
                    <div>
                      <div>{{ $fanyi("数量") }}：{{ goodsItem.is_now_data.num }}</div>
                      <div>{{ $fanyi("单价") }}：{{ goodsItem.is_now_data.price }}</div>
                      <div>{{ $fanyi("运费") }}{{ goodsItem.is_now_data.freight }}</div>
                    </div>
                  </li>
                  <!-- 出荷数 -->
                  <li class="dataAtTheTimeOfEntry" style="width:100px">
                    <div>
                      <div>{{ $fanyi("到着") }}：{{ goodsItem.in_out_data.in_num }}</div>
                      <div>{{ $fanyi("出荷") }}：{{ goodsItem.in_out_data.out_num }}</div>
                    </div>
                  </li>
                  <!-- オプション -->
                  <li class="optionSelect" style="margin-top:-10px">
                    <el-popover trigger="hover">
                      <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex"
                           style="text-align: left;">
                        <div v-if="optionItem.num>0&&optionItem.checked===true">{{ optionIndex + 1 }}.{{
                            optionItem.name_translate
                          }}
                          *{{ optionItem.num }}({{
                            optionItem.price
                          }}{{ optionItem.unit }}/点)
                        </div>
                      </div>
                      <div slot="reference">
                        <div class="optionHiddenContainer">
                          <div v-for="(optionItem,optionIndex) in goodsItem.option" :key="optionIndex"
                               style="text-align: left;width:149px">
                            <div v-if="optionItem.num>0&&optionItem.checked===true" class="u-line">
                              {{ optionIndex + 1 }}.{{
                                optionItem.name_translate
                              }} *{{ optionItem.num }}({{
                                optionItem.price
                              }}{{ optionItem.unit }}/点)
                            </div>
                          </div>
                        </div>
                        <div v-show="goodsItem.optionCheckedNum>3">........</div>
                      </div>
                    </el-popover>
                  </li>
                  <!-- 标签编号 -->
                  <li class="tagName" style="flex-direction: column">

                  </li>
                  <!-- 備考欄 -->
                  <li class="theDomesticFeed">
                    <el-popover trigger="hover">
                      <div>{{ goodsItem.client_remark }}</div>
                      <div slot="reference" class="clientRemark">{{ goodsItem.client_remark }}</div>
                    </el-popover>
                  </li>
                  <!-- スタッフ -->
                  <li class="salesmanRemark">
                    <div :title="goodsItem.y_reply" class="u-line-3" style="line-height: 20px">
                      {{ goodsItem.y_reply }}
                    </div>
                  </li>
                  <!--状态 -->
                  <li class="status">
                    <div style="line-height: 15px">
                      {{
                        goodsItem.problem_goods_status != null ? $fanyi(goodsItem.problem_goods_status) : $fanyi(goodsItem.status_name)
                      }}
                    </div>
                  </li>
                  <!--配送信息 -->
                  <li class="distributionInformation">
                    <div class="fontSize14" style="text-decoration: underline;color: #B4272B;cursor: pointer"
                         @click="getPorderMessage(goodsItem)">{{ $fanyi('查看') }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <SpecialNoteVue ref="SpecialNote" :goodsTotalPrice="goodsTotalPrice"
                          :goodsTotalServiceChargeDetailsList="goodsTotalServiceChargeDetailsList"
                          :goodsTotalServiceCharge="goodsTotalServiceCharge"
                          :optionTotalPrice="optionTotalPrice" :RMBTotalPrice="RMBTotalPrice"
                          :yenTotalPrice="yenTotalPrice" :obligationStatus="obligationStatus"
                          :orderStatus="orderStatus" :otherPrice="Number(datas.other_price)"
                          :businessRemark="datas.y_reply" :otherPriceRemark="datas.other_price_remark"
                          :selectOptionList="selectOptionList" :freightTotalPrice="freightTotalPrice"/>
        </div>
        <div class="operatingOptions" style="width: auto">
          <div class="choies">
            <input
                v-model="checked"
                type="checkbox"
                @change="allChoice"
            />
            <button class="qx" @click="checked = !checked;allChoice();">
              {{ $fanyi("全选") }}
            </button>
          </div>
          <div class="flexAndCenter">
            <div class="AdditionalBtn" @click="oneMoreOrder">再度注文</div>
            <div class="AdditionalBtn" @click="goodPushInventory">商品庫に入る</div>
            <div class="flex" style="margin:0 25px">{{ $fanyi('商品数') }}
              <div class="fontWeightBold colorB4272B" style="margin:0 5px">{{ goodsTotalNum }}</div>
              {{ $fanyi('件') }}
            </div>
            <div class="flex">{{ $fanyi('订单初始付款') }}：
              <div class="fontWeightBold colorB4272B" style="margin-right:5px">{{
                  $fun.roundFixed(RMBTotalPrice)
                }}
              </div>
              {{ $fanyi('元') }} （1{{ $fanyi('元') }}={{
                datas.exchange_rate
              }}{{ $fanyi('円') }}）
            </div>
          </div>
        </div>
      </div>
      <div v-show="activeName==='second'" style="padding-bottom:30px;margin-top: 10px">
        <div class="secondTabsTitleContainer flex">
          <div class="left">
            <div class="leftTitle color333">{{ $fanyi('注文書') }}</div>
            <div class="color333 fontSize14">{{ $route.query.order_sn }}</div>
            <div class="leftTitle color333" style="margin-top: 40px">{{ $fanyi('订单汇率') }}</div>
            <div class="color333 fontSize14">
              1{{ $fanyi('元') }}={{ orderAmountChangeDetail.exchange_rate }}{{ $fanyi('円') }}
            </div>
            <div class="priceTypeContainer flexAndCenter">
              <div v-for="(item,index) in priceType" :key="index" :class="priceTypeIndex===index?'activePriceType':''"
                   @click="priceTypeIndex=index">
                {{ item.type }}
              </div>
            </div>
          </div>
          <div class="category">
            <div class="categoryHeader">{{ $fanyi('类目') }}</div>
            <div class="categoryFooterContainer">
              <div>{{ $fanyi('商品金额') }}</div>
              <div>{{ $fanyi('手数料') }}</div>
              <div>{{ $fanyi('オプション手数料') }}</div>
              <div>{{ $fanyi('国内送料') }}</div>
              <div>{{ $fanyi('その他') }}</div>
              <div>{{ $fanyi('合計金額') }}</div>
            </div>
          </div>
          <div class="amountTimeDeposit">
            <div class="amountTimeDepositHeader">{{ $fanyi('入金时金额') }}</div>
            <div class="amountTimeDepositFooterContainer">
              <!--              商品金额-->
              <div class="amountTimeDepositFooterLabel">
                {{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_goods_old_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_goods_old_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--              手数料（5%）-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div class="amountTimeDepositFooterLabel">{{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_service_old_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_service_old_rmb)
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--                手数料明细-->
                <el-popover placement="bottom" trigger="hover" :append-to-body="false">
                  <div class="optionPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('手数料详情') }}</div>
                    <div class="optionPriceDetailHeaderContainer flexAndCenter">
                      <div class="content">{{ $fanyi('番号') }}</div>
                      <div class="quantity" style="color:#333;text-decoration: none;">{{ $fanyi('收费比例') }}</div>
                      <div class="subtotal">{{ $fanyi('手续费（元）') }}</div>
                    </div>
                    <div class="priceScrollContainer">
                      <div class="optionPriceDetailFooterContainer flexAndCenter"
                           v-for="(item,index) in orderAmountChangeDetail.collectData.service_old" :key="index">
                        <div class="content">{{ item.sorting }}</div>
                        <div class="quantity">{{
                            $fun.returnServiceRate(item.service_rate)
                          }}%
                        </div>
                        <div class="subtotal">{{ item.service_price }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--              オプション手数料-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div class="amountTimeDepositFooterLabel">
                  {{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_option_old_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_option_old_rmb)
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--                オプション手数料明细-->
                <el-popover placement="bottom" trigger="hover" :append-to-body="false">
                  <div class="optionPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('オプション详情') }}</div>
                    <div class="optionPriceDetailHeaderContainer flexAndCenter">
                      <div class="content">{{ $fanyi('内容') }}</div>
                      <div class="quantity" style="color:#333;text-decoration: none;">{{ $fanyi('数量') }}</div>
                      <div class="subtotal">{{ $fanyi('小计（元）') }}</div>
                    </div>
                    <div class="priceScrollContainer">
                      <div class="optionPriceDetailFooterContainer flexAndCenter"
                           v-for="(item,index) in orderAmountChangeDetail.collectData.option_old" :key="index">
                        <div class="content">{{ item.name_translate }}</div>
                        <div class="quantity">{{ item.num }}</div>
                        <div class="subtotal">{{ item.price_total }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--              国内送料-->
              <div class="amountTimeDepositFooterLabel">{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_freight_old_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_freight_old_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--              その他费用-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div>{{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_other_old_jpy) : $fun.roundFixed(Number(orderAmountChangeDetail.collectData.amount_other_old_rmb))
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--              その他费用费用-->
                <el-popover placement="bottom" trigger="hover">
                  <div class="restsPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('其他费用明细') }}</div>
                    <el-input v-model="orderAmountChangeDetail.collectData.other_old" readonly type="textarea"
                              style="width: 260px;" resize="none"/>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--              合計金額-->
              <div class="amountTimeDepositFooterLabel">
                {{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_sum_old_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_sum_old_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
            </div>
          </div>
          <div class="FinalOrderAmount">
            <div class="amountTimeDepositHeader" style="background: #DEFAE1;">{{ $fanyi('最终订单金额') }}</div>
            <!--             商品金额-->
            <div class="amountTimeDepositFooterContainer" style="background: #ECFBEE">
              <div class="amountTimeDepositFooterLabel">{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_goods_now_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_goods_now_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             手数料（5%）-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div class="amountTimeDepositFooterLabel">{{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_service_now_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_service_now_rmb)
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--                手数料明细-->
                <el-popover placement="bottom" trigger="hover" :append-to-body="false">
                  <div class="optionPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('手数料详情') }}</div>
                    <div class="optionPriceDetailHeaderContainer flexAndCenter">
                      <div class="content">{{ $fanyi('番号') }}</div>
                      <div class="quantity" style="color:#333;text-decoration: none;">{{ $fanyi('收费比例') }}</div>
                      <div class="subtotal">{{ $fanyi('手续费（元）') }}</div>
                    </div>
                    <div class="priceScrollContainer">
                      <div class="optionPriceDetailFooterContainer flexAndCenter"
                           v-for="(item,index) in orderAmountChangeDetail.collectData.service_now" :key="index">
                        <div class="content">{{ item.sorting }}</div>
                        <div class="quantity">{{ $fun.returnServiceRate(item.service_rate) }}%
                        </div>
                        <div class="subtotal">{{ item.service_price }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--             オプション手数料-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div class="amountTimeDepositFooterLabel">{{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_option_now_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_option_now_rmb)
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--             オプション手数料明细-->
                <el-popover placement="bottom" trigger="hover" :append-to-body="false">
                  <div class="optionPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('オプション详情') }}</div>
                    <div class="optionPriceDetailHeaderContainer flexAndCenter">
                      <div class="content">{{ $fanyi('内容') }}</div>
                      <div class="quantity" style="color: #606266;text-decoration:none ">{{ $fanyi('数量') }}</div>
                      <div class="subtotal">{{ $fanyi('小计（元）') }}</div>
                    </div>
                    <div class="priceScrollContainer">
                      <div class="optionPriceDetailFooterContainer flexAndCenter"
                           v-for="(item,index) in orderAmountChangeDetail.collectData.option_now" :key="index">
                        <div class="content">{{ item.name_translate }}</div>
                        <div class="quantity">{{ item.num }}</div>
                        <div class="subtotal">{{ item.price_total }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--             国内送料-->
              <div class="amountTimeDepositFooterLabel">{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_freight_now_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_freight_now_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             その他费用-->
              <div style="position: relative" class="amountTimeDepositFooterLabel">
                <div class="amountTimeDepositFooterLabel">{{
                    priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_other_now_jpy) : $fun.roundFixed(Number(orderAmountChangeDetail.collectData.amount_other_now_rmb))
                  }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
                </div>
                <!--             その他费用明细-->
                <el-popover placement="bottom" trigger="hover">
                  <div class="restsPriceDetailContainer">
                    <div class="optionPriceTitle">{{ $fanyi('其他费用明细') }}</div>
                    <el-input v-model="orderAmountChangeDetail.collectData.other_now" readonly type="textarea"
                              resize="none" style="width: 260px;"/>
                  </div>
                  <div class="detailText" slot="reference">{{ $fanyi('明细') }}</div>
                </el-popover>
              </div>
              <!--             合計金額-->
              <div class="amountTimeDepositFooterLabel">{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_sum_now_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_sum_now_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
            </div>
          </div>
          <div class="adjustmentAmount">
            <div class="amountTimeDepositHeader" style="background: #F8E2E2;width: 189px;">{{
                $fanyi('调整金额')
              }}
            </div>
            <div class="amountTimeDepositFooterContainer" style="background: #FAF2F2;width: 189px;">
              <!--             商品金额-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_goods_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_goods_change_rmb)
                }} {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             手数料（5%）-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_service_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_service_change_rmb)
                }}
                {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             オプション手数料-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_option_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_option_change_rmb)
                }}
                {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             国内送料-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_freight_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_freight_change_rmb)
                }}
                {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--             その他-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_other_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_other_change_rmb)
                }}
                {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
              <!--              合計金額-->
              <div>{{
                  priceTypeIndex === 1 ? $fun.yenNumSegmentation(orderAmountChangeDetail.collectData.amount_sum_change_jpy) : $fun.roundFixed(orderAmountChangeDetail.collectData.amount_sum_change_rmb)
                }}
                {{ priceTypeIndex === 1 ? $fanyi('円') : $fanyi('元') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeName==='second'" class="detailsOfChangesInAmount fontWeightBold" style="margin-bottom: 20px">
      <div class="detailsOfChangesInAmountTitle">{{ $fanyi("商品金额变动明细") }}</div>
      <ul class="flexAndCenter detailsOfChangesInCommodityAmountTableHeader">
        <li class="designation">{{ $fanyi("番号") }}</li>
        <li class="mirror" style="width:90px">{{ $fanyi("写真") }}</li>
        <li class="newGoods">{{ $fanyi("商品详细") }}</li>
        <li class="newRemark">{{ $fanyi("调整前数据") }}</li>
        <li class="newRemark"><span>{{ $fanyi("调整后数据") }}</span></li>
        <li class="newRemark">{{ $fanyi("调整前option") }}</li>
        <li class="newRemark"><span>{{ $fanyi("调整后option") }}</span></li>
        <li class="newTypeOfMoneyInAndOut" style="width: 100px"><span>No</span></li>
        <li class="newTypeOfMoneyInAndOut" style="width: 100px"><span>{{ $fanyi("出入金类别") }}</span></li>
        <li class="settlingTime"><span>{{ $fanyi("调整时间") }}</span></li>
        <li class="adjustSpecificDetails"><span>{{ $fanyi("调整具体明细") }}</span></li>
        <li class="newAmount">{{ $fanyi("返金额（円）") }}</li>
        <li class="newAmount">{{ $fanyi("追加额（円）") }}</li>
      </ul>
      <div v-for="(item,index) in goodsPriceUpdateDetail" :key="index"
           :style="item.serial_number_status===true?'background: #FCF2F2;':item.isUnfold === true&&item.serial_number_status===false?'background: #F9F9F9;':''">
        <ul class="flexAndCenter detailsOfChangesInCommodityAmountTableCenter">
          <!--          番号-->
          <li class="designation">{{ item.sorting }}</li>
          <!--          写真-->
          <li class="mirror" style="width:90px">
            <el-popover placement="right" trigger="hover">
              <el-image :src="item.pic" style="width: 300px; height: 300px"/>
              <el-image :src="item.pic" style="width: 60px;height: 60px" slot="reference"/>
            </el-popover>
            <el-popover placement="bottom" trigger="hover">
              <div>{{ $fanyi("手续费") }}：{{ $fun.returnServiceRate(item.service_rate) }}%</div>
              <div slot="reference">{{ item.from_platform }}</div>
            </el-popover>
          </li>
          <!--          商品詳細-->
          <li class="newGoods">
            <el-popover placement="right" trigger="hover">
              <div class="showDetail">
                <div class="showDetailCon">
                  <p v-for="(bitem, bindex) in item.detail"
                     :key="bitem.key + bindex"
                     :title="bitem.key + ':' + bitem.value">
                    {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                  </p>
                </div>
              </div>
              <div class="showDetail" slot="reference">
                <div class="showDetailCon" style="width:120px">
                  <p v-for="(bitem, bindex) in item.detail"
                     :key="bitem.key + bindex"
                     :title="bitem.key + ':' + bitem.value"
                     class="goodsDetailOpt ispay"
                     style="width:120px;"
                  >
                    {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                  </p>
                </div>
              </div>
            </el-popover>
          </li>
          <!--          调整前数据-->
          <li class="newRemark">
            <div class="newRemarkData">
              <div>{{ $fanyi("数量") }}：{{ item.num_goods_old }}</div>
              <div>{{ $fanyi("单价") }}：{{ item.amount_goods_old_rmb }}</div>
              <div>{{ $fanyi("运费") }}：{{ item.amount_freight_old_rmb }}</div>
            </div>
          </li>
          <!--          调整后数据-->
          <li class="newRemark">
            <div class="newRemarkData">
              <div>{{ $fanyi("数量") }}：{{ item.num_goods_now }}</div>
              <div>{{ $fanyi("单价") }}：{{ item.amount_goods_now_rmb }}</div>
              <div>{{ $fanyi("运费") }}：{{ item.amount_freight_now_rmb }}</div>
            </div>
          </li>
          <!--          调整前option-->
          <li class="newRemark">
            <el-popover trigger="hover">
              <div v-for="(optionItem,optionIndex) in item.option_old" :key="optionIndex" style="text-align: left;">
                <div v-if="optionItem.num>0&&optionItem.checked===true">
                  {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                    optionItem.price
                  }}{{ optionItem.unit }}/点)
                </div>
              </div>
              <div slot="reference">
                <div class="newOptionHiddenContainer">
                  <div v-for="(optionItem,optionIndex) in item.option_old" :key="optionIndex">
                    <div v-if="optionItem.num>0&&optionItem.checked===true" style="text-align: left;width: 109px;"
                         class="u-line">
                      {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                        optionItem.price
                      }}{{ optionItem.unit }}/点)
                    </div>
                  </div>
                </div>
              </div>
            </el-popover>
          </li>
          <!--          调整后option-->
          <li class="newRemark">
            <el-popover trigger="hover">
              <div v-for="(optionItem,optionIndex) in item.option_now" :key="optionIndex" style="text-align: left;">
                <div v-if="optionItem.num>0&&optionItem.checked===true">
                  {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                    optionItem.price
                  }}{{ optionItem.unit }}/点)
                </div>
              </div>
              <div slot="reference">
                <div class="newOptionHiddenContainer">
                  <div v-for="(optionItem,optionIndex) in item.option_now" :key="optionIndex">
                    <div v-if="optionItem.num>0&&optionItem.checked===true" style="text-align: left;width: 109px;"
                         class="u-line">
                      {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                        optionItem.price
                      }}{{ optionItem.unit }}/点)
                    </div>
                  </div>
                </div>
              </div>
            </el-popover>
          </li>
          <li v-show="item.isUnfold===false&&item.user_bill.length>0">
            <div class="flexAndCenter packUpDetailContainer" @click="unfoldOperation(item)"
                 :style="item.serial_number_status===true?'background: #F8E2E2;':''">
              <i class="el-icon-arrow-right"></i>
              <div>{{ $fanyi("点击展开显示明细") }}</div>
            </div>
          </li>
          <li v-show="item.isUnfold===true&&item.user_bill.length>0">
            <div class="flexAndCenter packUpDetailContainer" @click="unfoldOperation(item)"
                 :style="item.serial_number_status===true?'background: #F8E2E2;':''">
              <i class="el-icon-arrow-down"></i>
              <div>{{ $fanyi("点击收起明细") }}</div>
            </div>
          </li>
          <li class="newAmount" style="margin-left: 12px;line-height:50px"
              :style="item.user_bill.length===0?'margin-left: 470px;':''">
            -
          </li>
        </ul>
        <div v-show="item.isUnfold===true">
          <ul class="unfoldedUl flexAndCenter" v-for="(billItem,billIndex) in item.user_bill"
              :style="item.serial_number_status===true?'background: #FCF2F2;':''">
            <!--          出入金编号-->
            <li class="newTypeOfMoneyInAndOut" style="margin-left: 770px;width:100px">
              <span class="serial_number_text"
                    @click="churujinListPageJump">{{ billIndex + 1 }}
              </span>
            </li>
            <!--          出入金类别-->
            <li class="newTypeOfMoneyInAndOut color333 fontSize14" style="width:100px">{{
                billItem.client_bill_reason
              }}
            </li>
            <!--          调整时间-->
            <li class="settlingTime color333 fontSize14">{{ billItem.confirm_at }}</li>
            <!--          调整具体明细-->
            <li class="adjustSpecificDetails">
              <div class="colorB4272B cursorPointer fontSize14" style="text-decoration: underline;text-align: center"
                   @click="getAdjustDetail(billItem)">{{
                  $fanyi("详细")
                }}
              </div>
            </li>
            <!--          返金额（円）-->
            <li class="newAmount">{{ billItem.amount > 0 ? billItem.amount : '' }}</li>
            <!--          追加额（円）-->
            <li class="newAmount">{{ billItem.amount < 0 ? billItem.amount : '' }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!--    商品配送信息弹窗-->
    <div class="goodsOptionListContainer">
      <el-dialog
          :title="$fanyi('商品配送信息')"
          :visible.sync="goodsDistributionMessageDialogVisible"
          width="1300px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center
          :before-close="handleClose">
        <div class="otherExpensesHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="adjustSpecificDetails" style="width: 180px">{{ $fanyi("配送单号") }}</li>
            <li class="remark">{{ $fanyi("所在配送单箱号") }}</li>
            <li class="typeOfMoneyInAndOut">{{ $fanyi("提出日期") }}</li>
            <li class="time">{{ $fanyi("发货日期") }}</li>
            <li class="typeOfMoneyInAndOut">{{ $fanyi("包含订单") }}</li>
            <li class="totalAdjustmentAmount" style="margin-right: 0">{{ $fanyi("物流单号") }}</li>
            <li class="totalAdjustmentAmount" style="margin-right: 0">{{ $fanyi("配送单状态") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="otherExpensesFooter"
             style="flex-direction: column;height: 600px!important;overflow-y: scroll;overflow-x: hidden">
          <ul class="flexAndCenter color333" style="background: transparent;width:1240px"
              v-for="(item,index) in goodsDistributionMessageList" :key="index">
            <!--            配送单号-->
            <li class="adjustSpecificDetails"
                style="text-decoration: underline;color: #B4272B;cursor: pointer;width: 180px;line-height: normal!important;"
                @click="porderDetailsPageJump(item.porder_sn)">
              {{ item.porder_sn }}
            </li>
            <!--            所在配送单箱号-->
            <li class="remark">{{ item.box_number }}</li>
            <!--            提出日期-->
            <li class="typeOfMoneyInAndOut">{{ item.datetime_submit }}</li>
            <!--            发货日期-->
            <li class="time">{{ item.datetime_delivery }}</li>
            <!--            包含订单-->
            <li class="typeOfMoneyInAndOut">
              <div v-for="(orderNumberItem,orderNumberIndex) in item.in_order" :key="orderNumberIndex">
                {{ orderNumberItem }}
              </div>
            </li>
            <!--            物流单号-->
            <li class="totalAdjustmentAmount logisticsOrderNumber">
              {{ item.express_no }}
            </li>
            <!--            配送单状态-->
            <li class="totalAdjustmentAmount" style="margin-right: 0">{{ $fanyi(`${item.porder_status_name}`) }}</li>
          </ul>
        </div>
      </el-dialog>
      <el-dialog
          :title="$fanyi('调整具体明细')"
          :visible.sync="adjustDetailDialogVisible"
          width="600px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <div class="adjustDetailHead">
          <ul class="flexAndCenter color333 fontSize14">
            <li class="adjustDetailNo">NO</li>
            <li class="adjustDetailList">{{ $fanyi("详细") }}</li>
          </ul>
        </div>
        <!-- 单个商品 -->
        <div class="adjustDetailFooter">
          <ul class="flexAndCenter color333" style="background: transparent;width:540px"
              v-for="(item,index) in adjust_detail" :key="index">
            <!--            配送单号-->
            <li class="adjustDetailNo" style="text-decoration: underline;color: #B4272B;text-align: center">
              {{ index + 1 }}
            </li>
            <!--            所在配送单箱号-->
            <li class="adjustDetailList" style="width:480px;line-height: 20px!important;">
              <div style="padding:5px 0;width: 480px">
                <div v-for="(valueItem,valueIndex) in item" :key="valueIndex" style="text-align: left;width:300px">{{
                    valueItem
                  }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SpecialNoteVue from '../newCom/SpecialNote'
import statistics from "@/views/UserCenter/views/userOrder/views/components/statistics.vue";

export default {
  components: {
    SpecialNoteVue,
    statistics
  },
  props: {
    orderDetailsInfo: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      activeName: 'first',
      checked: false,
      fullscreenLoading: true,
      priceType: [{
        type: this.$fanyi('人民币')
      }, {
        type: this.$fanyi('日元')
      }],
      priceTypeIndex: 1,
      goodsDistributionMessageList: [],
      adjust_detail: [],
      goodNum: 0,
      orderStatus: 'purchase',
      freightTotalPrice: 0,
      obligationStatus: true,
      goodsTotalPrice: 0,
      goodsDistributionMessageDialogVisible: false,
      adjustDetailDialogVisible: false,
      goodsInfo: {},
      checkedList: [],
      orderAmountChangeDetail: {
        collectData: {}
      },
      deepCloneNewList: [],
      goodsTotalServiceChargeDetailsList: [],
      goodsDetailList: [],
      showGoodsOptionList: [],
      goodsPriceUpdateDetail: [],
      goodsTotalServiceCharge: 0,
      optionTotalPrice: 0,
      RMBTotalPrice: 0,
      optionPrice: 0,
      yenTotalPrice: 0,
      selectOptionList: [],
      updateOrderStatus: false,
      datas: this.orderDetailsInfo,
      goodsTotalNum: 0,
      input: '',
      snInfo: {},
      newList: [{
        is_pay_data: {num: 100, price: "10.00", freight: "10.00"},
        is_now_data: {num: 100, price: "10.00", freight: "10.00"},
        in_out_data: {in_num: 9, out_num: 0}
      }],
    }
  },
  created() {
    this.getOrderAmountChangeDetail(this.$route.query.order_sn);
    if (this.$route.query.type != undefined) {
      this.activeName = 'second';
    }
    this.$store.commit("updateClient_remark", {text: ''});
    this.$store.commit("updateFollow_remark", {text: ''});
  },
  methods: {
    // 通过订单号获取数据
    getData() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        res.data.forEach((item) => {
          if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
            item.name += '―定額会員限定ルート'
          }
        })
        this.$store.commit("getLogisticsTransportation", {logisticsTransportation: res.data});
        setTimeout(() => {
          this.optionTotalPrice = this.datas.order_amount.option_amount_rmb;
          this.goodsTotalPrice = this.datas.order_amount.goods_amount_rmb;
          this.freightTotalPrice = this.datas.order_amount.china_logistics_price_rmb;
          this.RMBTotalPrice = this.datas.order_amount.sum_amount_rmb;
          this.yenTotalPrice = this.datas.order_amount.sum_amount_jpy;
          if (this.datas.order_amount.discounts_detail.length === 0) {
            this.goodsTotalServiceCharge = this.datas.order_amount.service_amount_rmb;
          } else {
            this.goodsTotalServiceCharge = 0;
            this.RMBTotalPrice = Number(this.datas.order_amount.sum_amount_rmb) - Number(this.datas.order_amount.discounts_amount);
            this.yenTotalPrice = Number(this.datas.order_amount.sum_amount_jpy) - Number(this.datas.order_amount.discounts_amount_jpy);
          }
        }, 0)
        if (this.datas.logistics != undefined) {
          for (let i = 0; i < res.data.length; i++) {
            if (this.datas.logistics.id === res.data[i].id) {
              this.$refs.SpecialNote.selectValue = this.datas.logistics.id;
              this.$refs.SpecialNote.logisticsTransportationTips = res.data[i].tips
              break;
            } else {
              this.$refs.SpecialNote.selectValue = ''
            }
          }
        } else {
          this.$refs.SpecialNote.selectValue = ''
        }
        if (this.datas.order_file.length > 0) {
          this.datas.order_file.forEach((item) => {
            this.$refs.SpecialNote.fileList.push({
              name: this.$fun.deepClone(item.name),
              file: this.$fun.deepClone(item.file)
            })
          })
        }
        this.datas.other_price = Number(this.datas.other_price);
        this.$store.commit("updateClient_remark", {text: this.datas.client_remark});
        this.$store.commit("updateFollow_remark", {text: this.datas.follow_remark});
        let optionList = [];
        let arr = [];
        let detailArr = [];
        let detailArr2 = [];
        this.datas.order_detail.forEach((item, index) => {
          item.goods.forEach((goodsItems, goodsItemIndex) => {
            goodsItems.optionCheckedNum = 0;
            goodsItems.isUnfold = false;
            goodsItems.optionPrice = 0;
            goodsItems.checked = false;
            if (this.datas.order_amount.discounts_detail.length > 0) {
              goodsItems.service_rate = 0
            }
            if (goodsItems.option != null) {
              goodsItems.option.forEach((optionItem) => {
                if ((Number(optionItem.num) > 0 && optionItem.checked === true) || optionItem.price_type === 1) {
                  goodsItems.optionCheckedNum++
                  if (optionItem.price_type === 1) {
                    optionItem.total = (Number(goodsItems.is_now_data.price) * optionItem.num) * (Number(optionItem.price) / 100);
                    goodsItems.optionPrice += optionItem.total;
                  }
                  if (optionItem.price_type === 0) {
                    optionItem.total = Number(optionItem.num) * Number(optionItem.price);
                    goodsItems.optionPrice += Number(optionItem.num) * Number(optionItem.price);
                  }
                  optionList.push(this.$fun.deepClone(optionItem));
                }
              })
              goodsItems.totalPrice = goodsItems.optionPrice + (Number(goodsItems.is_now_data.price) * goodsItems.is_now_data.num) + Number(goodsItems.is_now_data.freight) + goodsItems.service_rate
            }
            if (goodsItems.set_detail_id < 0) {
              let arr = [];
              arr.push(this.$fun.deepClone(goodsItems));
              goodsItems.arr = arr;
              if (detailArr2.length == 0) {
                detailArr.push({
                  index: index,
                  goodsItemIndex: goodsItemIndex,
                  detailId: goodsItems.id,
                });
              } else {
                detailArr2.forEach((arrItem) => {
                  if (arrItem.detailId == goodsItems.id) {
                    goodsItems.arr.push(arrItem.info)
                  }
                })
              }
            }
            if (goodsItems.set_detail_id > 0) {
              if (detailArr.length > 0) {
                detailArr.forEach((arrItem) => {
                  if (arrItem.detailId == goodsItems.set_detail_id) {
                    this.datas.order_detail[arrItem.index].goods[arrItem.goodsItemIndex].arr.push(this.$fun.deepClone(goodsItems));
                    this.$forceUpdate();
                  }
                })
              } else {
                detailArr2.push({
                  index: index,
                  goodsItemIndex: goodsItemIndex,
                  detailId: goodsItems.set_detail_id,
                  info: goodsItems
                });
              }
            }
            if (goodsItems.set_detail_id <= 0) {
              arr.push(goodsItems);
            }
          })
        })
        this.newList = arr;
        this.deepCloneNewList = this.$fun.deepClone(arr);
        if (optionList.length > 0) {
          let end = [];
          end.push(optionList[0]);
          for (let i = 1; i < optionList.length; i++) {
            optionList[i].num = Number(optionList[i].num);
            let j = 0
            for (; j < end.length; j++) {
              // 6. 判断两个数组中的id是否相同
              if (optionList[i].name === end[j].name) {
                // 7.相等就将我们比较的对象 `arr[i]` 存储到  相同id对象的list 中
                end[j].num = Number(end[j].num);
                end[j].num += optionList[i].num;
                end[j].total += optionList[i].total
                // *** 8.这里要理解，我们每次遍历，其实都是处理  `arr[i]` ，而上面的逻辑已经处理了，所以终止后续的循环 break;
                break;
              }
            }
            if (j === end.length) {
              end.push(optionList[i])
            }
          }
          this.selectOptionList = end;
        }
        setTimeout(() => {
          this.commodityTotal();
        }, 0)
        this.fullscreenLoading = false;
      })
    },
    updateForSn(val) {
      if (val.for_sn != '') {
        this.$api.updateOrderDetailForSn({order_detail_id: val.id, for_sn: val.for_sn}).then((res) => {
          this.getData();
        });
      }
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    //加入商品库
    goodPushInventory() {
      let arr = [];
      this.newList.forEach((item) => {
        if (item.arr == undefined && item.checked === true) {
          let optionList = [];
          let tagList = [];
          if (item.option != null) {
            item.option.forEach((optionItem) => {
              optionList.push(optionItem)
            })
          }
          if (item.order_detail_tag.length > 0) {
            item.order_detail_tag.forEach((tagItem) => {
              if (tagItem.no !== '' || tagItem.goods_no !== '') {
                tagList.push({
                  type: tagItem.type,
                  no: tagItem.no,
                  goods_no: tagItem.goods_no
                })
              }
            })
          }
          arr.push({
            shop_type: item.from_platform,
            shop_id: item.shop_id,
            shop_name: item.shop_name,
            goods_id: item.goods_id,
            goods_title: item.goods_title,
            img_url: item.pic,
            detail: JSON.stringify(item.detail),
            option: optionList.length > 0 ? JSON.stringify(optionList) : '[]',
            num: Number(item.is_now_data.num) > 0 ? item.is_now_data.num : 1,
            price: item.is_now_data.price,
            for_sn: item.for_sn != undefined ? item.for_sn : undefined,
            sku_id: item.sku_id,
            spec_id: item.spec_id,
            note: item.client_remark,
            inventory_tag: tagList.length > 0 ? tagList : undefined
          })
        } else if (item.arr != undefined) {
          item.arr.forEach((arrItem) => {
            let optionList = [];
            let tagList = [];
            if (arrItem.option != null) {
              arrItem.option.forEach((optionItem) => {
                optionList.push(optionItem)
              })
            }
            if (arrItem.order_detail_tag.length > 0) {
              arrItem.order_detail_tag.forEach((tagItem) => {
                if (tagItem.no !== '' || tagItem.goods_no !== '') {
                  tagList.push({
                    type: tagItem.type,
                    no: tagItem.no,
                    goods_no: tagItem.goods_no
                  })
                }
              })
            }
            arr.push({
              shop_type: arrItem.from_platform,
              shop_id: arrItem.shop_id,
              shop_name: arrItem.shop_name,
              goods_id: arrItem.goods_id,
              goods_title: arrItem.goods_title,
              img_url: arrItem.pic,
              detail: JSON.stringify(arrItem.detail),
              option: optionList.length > 0 ? JSON.stringify(optionList) : '[]',
              num: Number(arrItem.is_now_data.num) > 0 ? arrItem.is_now_data.num : 1,
              price: arrItem.is_now_data.price,
              for_sn: arrItem.for_sn != undefined ? arrItem.for_sn : undefined,
              sku_id: arrItem.sku_id,
              spec_id: arrItem.spec_id,
              note: arrItem.client_remark,
              inventory_tag: tagList.length > 0 ? tagList : undefined
            })
          })
        }
      })
      if (arr.length === 0) {
        this.$message.error(this.$fanyi('商品をご選択ください！'));
        return
      }
      let data = {
        data: arr
      }
      this.$api.favoriteInventoryBatchSave(data).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
      })
    },
    // 单选
    radio(i) {
      this.checkedList = [];
      this.newList.forEach((item) => {
        if (item.arr == undefined && item.checked) {
          this.checkedList.push(item)
        } else if (item.arr != undefined) {
          item.arr.forEach((arrItem) => {
            if (arrItem.checked) {
              this.checkedList.push(arrItem)
            }
          })
        }
      })
      this.$forceUpdate();
    },
    markerAmazon() {
      let count = 0;
      let arr = [];
      this.newList.forEach((item) => {
        if (item.checked == true) {
          count++
          item.warehousing = !item.warehousing
          this.$forceUpdate();
          arr.push(item.id)
        }
      })
      if (count == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'))
        return
      }
      this.$api.markersWarehouseGoods({
        order_sn: this.$route.query.order_sn,
        order_detail_id: arr
      }).then((res) => {
      })
    },
    allChoice() {
      this.newList.forEach((item) => {
        if (item.arr == undefined) {
          item.checked = this.checked;
        } else {
          item.arr.forEach((arrItem) => {
            arrItem.checked = this.checked
          })
        }
      });
      this.$forceUpdate();
    },
    oneMoreOrder() {
      let arr = [];
      this.newList.forEach((item) => {
        if (item.arr == undefined && item.checked) {
          arr.push(item.id)
        } else if (item.arr != undefined) {
          item.arr.forEach((item2) => {
            if (item2.checked) {
              arr.push(item2.id)
            }
          })
        }
      })
      if (arr.length === 0) {
        this.$message.warning(this.$fanyi("请选择要提出订单的商品"));
        return
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过历史订单下单的商品个数统计', arr.length);
      }
      this.$api.copyOrder({order_sn: this.$route.query.order_sn, order_detail_id: arr}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg));
        this.$store.commit("setExcelOrderPreviewInfo", res.data)
        setTimeout(() => {
          window.open('/excelOrderPreview?id=100&type=temporary')
        }, 1000)
      })
    },
    linkSearch() {
      if (this.input !== '') {
        let arr = [];
        this.$api.goodsUrlToGoodsId({goods_url: this.input}).then((res) => {
          this.newList.forEach((item) => {
            if (item.arr == undefined && item.goods_id === res.data.goods_id) {
              arr.push(item)
            } else if (item.arr != undefined) {
              item.arr.forEach((item2) => {
                if (item2.goods_id === res.data.goods_id) {
                  arr.push(item2);
                }
              })
            }
          })
          if (arr.length === 0) {
            this.$message.error(this.$fanyi("订单中未找到该商品"))
          } else {
            this.newList = arr;
          }
        });
      }
    },
    //重置商品链接搜索
    resetLinkSearch() {
      this.input = '';
      this.newList = this.$fun.deepClone(this.deepCloneNewList);
    },
    // 通过订单号获取订单金额变动明细
    getOrderAmountChangeDetail(order_sn) {
      this.$api.orderAmountChangeDetail({
        order_sn: order_sn
      }).then((res) => {
        this.orderAmountChangeDetail = res.data;
        res.data.detailData.forEach((item) => {
          item.isUnfold = false;
          if (this.$route.query.type == 1 && item.user_bill != undefined) {
            item.user_bill.forEach((user_bill_item) => {
              if (user_bill_item.id == this.$route.query.serial_number_id) {
                item.serial_number_status = true;
              }
            })
          }
        })
        this.goodsPriceUpdateDetail = res.data.detailData;
        this.getData();
      });
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.goodsTotalNum = 0;
      this.goodNum = 0;
      this.newList.forEach((item, i) => {
        if (item.arr == undefined) {
          this.goodsTotalNum += item.is_now_data.num;
          this.goodNum += item.num;
          this.goodsTotalServiceChargeDetailsList.push({
            No: i + 1,
            service_rate: this.datas.order_amount.discounts_detail.length === 0 ? this.$fun.returnServiceRate(item.service_rate) : 0,
            value: this.datas.order_amount.discounts_detail.length === 0 ? (Number(item.is_now_data.price) * item.is_now_data.num) * item.service_rate : 0
          })
        } else {
          item.arr.forEach((item2, i) => {
            this.goodsTotalNum += item2.is_now_data.num;
            this.goodNum += item2.num;
            this.goodsTotalServiceChargeDetailsList.push({
              No: i + 1,
              service_rate: this.datas.order_amount.discounts_detail.length === 0 ? this.$fun.returnServiceRate(item2.service_rate) : 0,
              value: this.datas.order_amount.discounts_detail.length === 0 ? (Number(item2.is_now_data.price) * item2.is_now_data.num) * item2.service_rate : 0
            })
          })
        }
      });
    },
    //展开操作
    unfoldOperation(item) {
      item.isUnfold = !item.isUnfold;
      this.$forceUpdate();
    },
    //获取金额变更明细
    getAdjustDetail(item) {
      this.adjust_detail = item.adjust_detail;
      this.adjustDetailDialogVisible = true;
    },
    //获取配送单信息
    getPorderMessage(item) {
      this.$api.onePorderOverview({
        order_sn: this.$route.query.order_sn,
        order_detail_id: item.id
      }).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.goodsDistributionMessageList = res.data;
        this.goodsDistributionMessageDialogVisible = true;
      });
    },
    //订单问题产品页面跳转
    issueOrderDetailPageJump() {
      let routeData = this.$router.resolve({
        path: "/issueOrderDetail",
        query: {
          order_sn: this.$route.query.order_sn
        }
      });
      window.open(routeData.href, "_blank");
    },
    //出入金详细页面跳转
    churujinListPageJump() {
      let routeData = this.$router.resolve({
        path: "/user/churujinList",
        query: {
          order_sn: this.$route.query.order_sn,
          serial_number_id: this.$route.query.serial_number_id
        }
      });
      window.open(routeData.href, "_blank");
    },
    //配送单详情页面跳转
    porderDetailsPageJump(order) {
      let routeData = this.$router.resolve({
        path: "/deliveryDetails",
        query: {
          pOrder_sn: order,
        },
      });
      window.open(routeData.href, "_blank");
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`)
      .then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/orderDetails.scss";
@import "../css/purchaseOrderDetailsPage.scss";

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}

.tagListContainer {
  position: absolute;
  left: 820px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 157.39px;
  background: #FFEFDA;
}
</style>
